<script>
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
      breadItems: [
        {
          text: "Отчет ПНЛ",
          disabled: false,
          href: "/#/reports"
        },
        {
          text: "Проживание",
          disabled: true,
          href: "breadcrumbs_link_2"
        }
      ],
      headers: [
        { value: "category", text: "Доход", sortable: false },
        { value: "price", text: "Сумма, тг", sortable: false }
      ],
      items: [
        // {
        //   category: "Категория 1",
        //   price: 100000
        // }
      ],
      res: {},
      loading: false,
      options: {}
    };
  },
  created() {
    console.log(this.$route.params.id);
    let paths = {
      booking: "Проживание",
      service: "Услуги",
      expenditure: "Расходы"
    };
    this.breadItems[1].text = paths[this.$route.params.id];
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;
        let res = await window.axios.post(
          `/api/v2/booking/pnl/${this.$route.params.id}/`,
          {
            after: this.$route.query.start,
            before: this.$route.query.end,
            check_in: this.$route.query.check_in == "booking"
          }
        );
        console.log(res.data);
        for (const [key, value] of Object.entries(res.data)) {
          if (key == "total") continue;
          this.items.push({
            category: key,
            price: value
          });
        }
        this.res = res.data;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<template>
  <v-container class="report">
    <v-card class="px-5 py-3">
      <v-breadcrumbs class="ma-0 pa-0" :items="breadItems"></v-breadcrumbs>
      <h3 class="mt-4">
        Период: {{ moment($route.query.start).format("DD.MM.YYYY") }} -
        {{ moment($route.query.end).format("DD.MM.YYYY") }}
      </h3>
      <v-data-table
        :headers="headers"
        :items="items"
        item-key="name"
        hide-default-footer
        :loading="loading"
        loading-text="Идёт загрузка данных..."
        :options.sync="options"
        :server-items-length="items.length"
      >
        <template v-slot:[`item.price`]="{ item }">
          {{ item.price.toLocaleString("KZ-kz") }}
        </template>
      </v-data-table>
      <div class="report_final" v-if="res.total > -1">
        <p>Итого</p>
        <p>{{ res.total.toLocaleString("KZ-kz") }} тг</p>
      </div>
    </v-card>
  </v-container>
</template>

<style scoped lang="scss">
.report {
  h3 {
    font-family: "Gilroy", sans-serif;
    font-weight: 500;
    font-size: 26px;
    color: #021011;
    @media (orientation: portrait) {
      font-size: 18px;
    }
  }
  &_final {
    margin-top: 40px;
    background: rgba(255, 165, 0, 0.2);
    font-family: "Gilroy", sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #ffa500;
    padding: 13px;
    display: grid;
    grid-template-columns: repeat(2, 55%);
    p {
      margin: 0;
    }
  }
}
</style>
